import React from "react";
import { Container } from "react-bootstrap";
// import { register } from '../../Firestore';
// import { isFormValidated } from '../../../utils/formUtils'

const Home = () => {

  // const initialState = {
  //   lastName: '',
  //   otherNames: '',
  //   address: '',
  //   telephone: '',
  //   email: '',
  //   dob: '',
  //   category: 'Member',
  //   product: 'BSIIM Savers Card',
  //   others: '',
  //   amountPaid: 0,
  //   aboutUs: ''
  // }

  // const [localState, setLocalState] = useState(initialState)


  // const handleChange = (input) => (event) => {
  //   setLocalState({
  //     ...localState,
  //     [input]: event.target.value
  //   })
  // }

  // const submitForm = async (event) => {
  //   if(!isFormValidated('registration')){
  //     return;
  //   }

  //   event.preventDefault()

  //   await register(localState)
  //   clearForm()
  // }

  // const clearForm = () => {
  //   setLocalState(initialState)
  // }

  return (
    <>
      <Container>
        <h3 className="text-center mt-5">BSIIM Registration</h3>
        <div className='row d-flex justify-content-center align-items-center'>
          <div className='col-md-6 col-sm-12 mt-5'>
            <div className="required">
              <i className="text-danger mr-2">*</i>
              Required
            </div>
            <hr />
            <form name='registration' method='post' data-netlify='true'>
              <input type='hidden' name='form-name' value='registration' />
              <fieldset>

                {/* last name */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Last Name</label>
                  <input className='form-control' required type='text' name='lastName' />
                </div>
                {/* other names */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Other Names</label>
                  <input className='form-control' required type='text' name='otherNames' />
                </div>
                {/* address */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Address</label>
                  <textarea className='form-control' required type='text' rows={2} name='address'></textarea>
                </div>
                {/* telephone */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Phone</label>
                  <input className='form-control' required type='number' maxLength={13} name='phone' />
                </div>
                {/* email */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Email</label>
                  <input className='form-control' required type='email' name='email' />
                </div>
                {/* date of birth */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Date of Birth</label>
                  <input className='form-control' required type='date' name='dob' />
                </div>
                {/* category - member, volunteer, supporter, customer */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Category</label>
                  <select className='form-control' required type='text' name='category'>
                    <option value='-'>-</option>
                    <option value="Member">Member</option>
                    <option value="Volunteer">Volunteer</option>
                    <option value="Supporter">Supporter</option>
                    <option value="Customer">Customer</option>
                  </select>
                </div>
                {/* product */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Product</label>
                  <select className='form-control' required type='text' name='product'>
                    <option value='-'>-</option>
                    <option value="BSIIM Savers Card">BSIIM Savers Card</option>
                    <option value="SG-Business Model">SG-Business Model</option>
                    <option value="EDW-Career Model">EDW-Career Model</option>
                    <option value="JS-Resource Model">JS-Resource Model</option>
                    <option value="GEI-Intelligence Model">GEI-Intelligence Model</option>
                  </select>
                </div>
                {/* others */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Others</label>
                  <input className='form-control' type='text' name='others' />
                  <span className="text-muted">Please Specify</span>
                </div>
                {/* amount paid */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>Amount Paid</label>
                  <input className='form-control' required type='number' name='amountPaid' />
                </div>
                {/* hear about us */}
                <div className='my-3'>
                  <label><i className="text-danger mr-2">*</i>How Did you Hear About Us</label>
                  <input className='form-control' required type='text' name='hearAboutUs' />
                </div>
                {/* register button */}
                <button type='submit' className='btn btn-block btn-dark mb-3'>REGISTER</button>
                <h5>Note: No refund after payment</h5>

              </fieldset>
            </form>
          </div>
        </div>
      </Container>
    </>
  );
};

export default Home;
